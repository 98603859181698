import React from "react";
import GreenHeroBanner from "../../assets/images/green_hero.jpg";
import GeneralButton from "../generalButton/generalButton.component";


const GreenHero = () => {
  return (
    <div className="hero-banner hero-banner--full hero-banner--greenpage">
      <img className="hero-banner__background-image" src={GreenHeroBanner} type="image/jpeg" alt="" />
      <div className="hero-banner__green-outer">
        <section className="hero-banner__content">
          <h1><b>Help your customers help the environment</b></h1>
          <p>Energy efficiency measures and green home retrofit technologies are crucial to the transition to net-zero ready homes, as are the financial products and services which will facilitate their roll-out. <br /><br />
            The Broker’s Handbook created by Green Finance Institute seeks to inform brokers about different green home retrofit solutions and technologies by providing a profile of the options available and their associated opportunities and risks, as well as quality assurance standards.
          </p>
          <GeneralButton
            additionalClass='log-in green-hero-button'
            isRed
            onClick={() => {
              window.open('https://www.greenfinanceinstitute.co.uk/programmes/ceeb/brokers-handbook/', '_blank'); return false;
            }}
          >
            Brokers Handbook
          </GeneralButton>
        </section>
        {/*  <img className="hero-banner__side-image" src={`${ClubAwards}`} alt="" type="image/jpeg" /> */}
      </div>
    </div>
  );
};

export default GreenHero;
